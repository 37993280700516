import { QuestionCircleOutlined } from '@ant-design/icons'
import { Button, Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import MaskedInput from 'react-input-mask'
import { Link } from 'react-router-dom'
import Keyboard from 'react-simple-keyboard'

import { START_PATH } from '../utils/const'
import {
	displayLetters,
	displaySt,
	domainsLayout,
	layoutLetters,
	layoutSt,
} from '../utils/typeKeyBoard'
import {
	emailValid,
	personalNumber,
	phoneField,
} from '../utils/validators/validators'

type Inputs = {
	personalNumber: string
	mail: string
	phone: string
}

export const Receive = () => {
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [currentInput, setCurrentInput] = useState<null | string>(null)
	const [isCorrect, setIsCorrect] = useState<boolean>(false)
	const [open, setOpen] = useState(false)
	const [typeKeyboard, setTypeKeyboard] = useState<string>('numbers')

	const {
		register,
		control,
		getValues,
		trigger,
		setValue,
		watch,
		formState: { errors },
	} = useForm<Inputs>()

	const onFocus = (name: string) => {
		if (currentInput && currentInput !== name) {
			//@ts-ignore
			trigger(currentInput)
		}
		setCurrentInput(name)
	}
	const onSubmit = (data: any) => {
		trigger().then((result) => {
			if (result) {
				console.log(data)
			}
		})
	}
	const onKeyPress = (button: any) => {
		if (button === '{enter}') {
			//onSubmit(getValues());
		} else {
			if (currentInput) {
				if (button === '{bksp}') {
					//@ts-ignore
					setValue(currentInput, `${getValues(currentInput).slice(0, -1)}`)
				} else {
					//@ts-ignore
					setValue(currentInput, `${getValues(currentInput)}${button}`)
				}
			}
		}
	}

	useEffect(() => {
		if (currentInput === 'mail') {
			setTypeKeyboard('text')
		} else {
			setTypeKeyboard('numbers')
		}
	}, [currentInput])

	useEffect(() => {
		setCurrentInput('personalNumber')
	}, [])
	return (
		<>
			<div className={`transfer payment ${typeKeyboard === 'text' && '_text'}`}>
				<div className="transfer__top">
					<Button
						className="transfer__info"
						type="primary"
						shape="circle"
						size="large"
						style={{ width: '65px', height: '65px' }}
						onClick={() => setIsModalOpen(true)}
						icon={<QuestionCircleOutlined style={{ fontSize: '40px' }} />}
					></Button>

					<h2 className="transfer__title title">Получить счета на E-mail</h2>
					<form className="transfer__form">
						<ul className="transfer__list">
							<li className="transfer__item">
								<label className="transfer__label" htmlFor="personalNumber">
									Номер лицевого счета
								</label>
								<div className="transfer__input-wrap">
									<div className="transfer__input-inner">
										<input
											className={`transfer__input ${errors.personalNumber ? '_error' : ''}`}
											id="personalNumber"
											type="text"
											placeholder="123456789012"
											onFocus={() => onFocus('personalNumber')}
											{...register('personalNumber', personalNumber)}
										/>
									</div>

									<div className="transfer__input-text">
										Введите 8 или 12 цифр лицевого счета
									</div>
								</div>
								<div className="transfer__input-error">
									{errors.personalNumber && errors.personalNumber.message}
								</div>
							</li>
							<li className="transfer__item">
								<label className="transfer__label" htmlFor="phone">
									Номер телефона
								</label>
								<div className="transfer__input-wrap">
									<div className="transfer__input-inner">
										<Controller
											name={'phone'}
											control={control}
											defaultValue=""
											rules={phoneField}
											render={({ field }) => (
												// eslint-disable-next-line react/jsx-no-undef
												<MaskedInput
													mask="+7 (999) 999-99-99"
													maskChar=""
													value={field.value ? field.value : ''}
													onChange={field.onChange}
													className={`transfer__input `}
													id="phone"
													onFocus={() => onFocus('phone')}
													placeholder={'+7 (999) 999-99-99'}
												></MaskedInput>
											)}
										/>
									</div>

									<div className="transfer__input-text">
										Введите ваш контактный телефон
									</div>
								</div>
								<div className="transfer__input-error">
									{errors.phone && errors.phone.message}
								</div>
							</li>
							<li className="transfer__item">
								<label className="transfer__label" htmlFor="mail">
									Электронная почта
								</label>
								<div className="transfer__input-wrap">
									<div className="transfer__input-inner">
										<input
											className={`transfer__input ${errors.mail ? '_error' : ''}`}
											id="mail"
											type="text"
											placeholder="example@example.ru"
											onFocus={() => onFocus('mail')}
											{...register('mail', emailValid)}
										/>
									</div>

									<div className="transfer__input-text">
										Введите e-mail адрес для получения квитанций
									</div>
								</div>
								<div className="transfer__input-error">
									{errors.mail && errors.mail.message}
								</div>
							</li>
						</ul>
					</form>
					<button
						className="transfer__btn btn"
						onClick={() => onSubmit(getValues())}
						disabled={!isCorrect}
					>
						Продолжить
					</button>
				</div>

				<div className="transfer__bottom ">
					{typeKeyboard === 'numbers' && (
						<Keyboard
							onKeyPress={onKeyPress}
							layout={layoutSt}
							display={displaySt}
						/>
					)}
					{typeKeyboard === 'text' && (
						<div style={{ display: 'flex' }}>
							<Keyboard
								baseClass={'simple-keyboard-numpadStart'}
								onKeyPress={onKeyPress}
								layout={layoutLetters}
								display={displayLetters}
							/>
							<Keyboard
								baseClass={'simple-keyboard-numpadEnd'}
								mergeDisplay={true}
								onKeyPress={onKeyPress}
								layout={domainsLayout}
								display={displayLetters}
							/>
						</div>
					)}
				</div>
			</div>
			<Modal
				title="Получение счетов на email"
				open={isModalOpen}
				className="modal-text"
				width={1000}
				onCancel={() => setIsModalOpen(false)}
				footer={[
					<Button type="primary" onClick={() => setIsModalOpen(false)}>
						Закрыть
					</Button>,
				]}
			>
				<p>Использование данного сервиса позволит Вам:</p>

				<ul>
					<li>
						Получать счет на оплату на 5-7 дней раньше, чем вы получите его
						обычной почтой;
					</li>
					<li>
						Не беспокоиться о том, что Ваш платежный документ будет утерян при
						доставке или будет доставлен не в Ваш адрес;
					</li>
					<li>
						В любой момент распечатать дубликат счета со своей электронной почты
						без посещения абонентской службы;
					</li>
					<li>
						Внести свой небольшой вклад в защиту окружающей среды, отказавшись
						от бумажных счетов.
					</li>
				</ul>
			</Modal>
			<Modal
				open={open}
				centered
				className="modal-text"
				onClose={() => setOpen(false)}
				onCancel={() => setOpen(false)}
				title="Заявка успешно создана"
				footer={[
					<div className="transfer__btn-wrap">
						<Link to={START_PATH} className="btn transfer__btn-modal">
							Вернуться на главную
						</Link>
					</div>,
				]}
			>
				<div className="transfer__modal-inner">
					<div className="transfer__modal-title">Лицевой счет:</div>
					<div className="transfer__modal-text">234432</div>
				</div>
				<div className="transfer__modal-inner">
					<div className="transfer__modal-title">Телефон</div>
					<div className="transfer__modal-text">132312</div>
				</div>
				<div className="transfer__modal-inner">
					<div className="transfer__modal-title">
						Почта для получения квитанций:
					</div>
					<div className="transfer__modal-text">234234</div>
				</div>
			</Modal>
		</>
	)
}
