import { Button, Modal } from 'antd'
import { useState } from 'react'
import { Link } from 'react-router-dom'

import SBPImage from '../assets/images/sbp.svg'
import CardImage from '../assets/images/v.svg'
import { PAYMENT_CARD_PATH, PAYMENT_SBP_PATH } from '../utils/const'

export const Payment = () => {
	const [isModalOpen, setIsModalOpen] = useState(false)
	return (
		<div className="payment">
			<h2 className="payment__title title">Оплата газа</h2>
			<ul className="payment__inner">
				<li className="payment__item">
					<Link to={PAYMENT_SBP_PATH} className="payment__item-link">
						<div className="payment__item-title">
							Оплата через СБП (по QR-коду) <br></br> БЕЗ КОМИССИИ
						</div>
						<div className="payment__logo-wrap">
							<img className="payment__logo" src={SBPImage} alt="payment" />
						</div>

						<div className="payment__link btn">Оплата через СБП</div>
					</Link>
					<button
						onClick={() => setIsModalOpen(true)}
						className="payment__instruction"
					>
						Инструкция для оплаты по QR-коду
					</button>
				</li>
				<li className="payment__item">
					<Link to={PAYMENT_CARD_PATH} className="payment__item-link">
						<div className="payment__item-title">
							Оплата банковской картой<br></br> БЕЗ КОМИССИИ
						</div>
						<div className="payment__logo-wrap">
							<img className="payment__logo" src={CardImage} alt="payment" />
						</div>
						<div className="payment__link btn">Оплата картой</div>
					</Link>
				</li>
			</ul>
			<Modal
				title="Оплата через СБП"
				open={isModalOpen}
				className="modal-text"
				width={'800px'}
				onCancel={() => setIsModalOpen(false)}
				footer={[
					<Button type="primary" onClick={() => setIsModalOpen(false)}>
						Закрыть
					</Button>,
				]}
			>
				<ol>
					<li>
						Нажмите на кнопку «Оплатить по QR-коду» в разделе «ОПЛАТА ГАЗА»
						данного терминала
					</li>
					<li>
						Введите <b>Номер лицевого счёта абонента</b> (8 или 12 символов),{' '}
						<b>Адрес электронной почты</b>
						(будет использован для получения вами чека платежа) и{' '}
						<b>Сумму платежа</b>.
					</li>
					<li>
						Нажмите кнопку <b>«Отправить»</b>
					</li>
					<li>
						На экране терминала отобразится QR-код для оплаты и параметры
						платежа, которые вы ввели, проверьте введенные данные, если что-то
						неверно вы можете нажать кнопку <b>«Внести изменения»</b> или{' '}
						<b>«Отменить платеж»</b>
					</li>
				</ol>
				<p>
					<b>
						Внимание!{' '}
						<span style={{ textDecoration: 'underline' }}>QR-код</span>,
						отображенный на экране,{' '}
						<span style={{ textDecoration: 'underline' }}>
							действителен 2 минуты
						</span>
						.
					</b>
					Платеж нужно совершить в течении этого времени.
				</p>
				<ol start={5}>
					<li>
						Если данные для оплаты верны, откройте на смартфоне{' '}
						<span style={{ textDecoration: 'underline' }}>
							приложение своего банка
						</span>{' '}
						(например Сбербанк-онлайн, Телекард, Альфа-Клик и др.).
					</li>
					<li>
						В разделе <b>«Платежи»</b> нажмите кнопку <b>«Оплата по QR-коду»</b>{' '}
						(название может отличаться) или найдите кнопку с соответствующей
						пиктограммой. Откроется видоискатель камеры.
					</li>
					<li>
						Наведите камеру на QR-код. После считывания QR-кода в приложении
						банка появится платежная форма со следующими данными
						<ul>
							<li>
								<span style={{ textDecoration: 'underline' }}>
									наименование получателя
								</span>{' '}
								- ООО «Газпром межрегионгаз Оренбург»,
							</li>
							<li>
								<span style={{ textDecoration: 'underline' }}>
									назначение платежа
								</span>{' '}
								- «Оплата газа ЛС (<i>номер лицевого который вы ввели</i>)»
							</li>
							<li>
								<span style={{ textDecoration: 'underline' }}>
									сумма к оплате
								</span>{' '}
								- указанная вами сумма.
							</li>
						</ul>
						Проверьте, правильно ли указаны все данные.
					</li>
					<li>
						Для подтверждения платежа Нажмите <b>«Оплатить»</b> или{' '}
						<b>«Подтвердить»</b> (банки могут использовать разные названия
						кнопок). При достаточности средств на вашем счете, происходит
						списание, поставщик газа и вы получите уведомление об оплате.
					</li>
				</ol>
				<h3>Чек и зачисление средств</h3>
				<ul>
					<li>
						В результате успешного проведения платежа на Электронную почту,
						указанную вами, будет направлен чек об операции.
					</li>
					<li>Оплата отразится на вашем лицевом счёте в течение 1-3-х дней.</li>
				</ul>
			</Modal>
		</div>
	)
}
