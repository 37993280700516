import ReactPlayer from 'react-player'
import { useNavigate } from 'react-router-dom'

import { Advertising } from '@/components'

//@ts-ignore
import video from '../assets/video/dogaz.webm'

export const Gasification = () => {
	const navigate = useNavigate()
	return (
		<div className={'advertising'}>
			<button className="back-btn" onClick={() => navigate(-1)}>
				Вернуться назад
			</button>
			<h1 className="title">Догазификация</h1>
			{/* <video src={video}></video> */}
			<div className="advertising__video">
				<ReactPlayer
					url={video}
					width="100%"
					playing
					height="100%"
					volume={1}
				/>
			</div>
		</div>
	)
}
