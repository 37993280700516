import { QuestionCircleOutlined } from '@ant-design/icons'
import { Button, Modal } from 'antd'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import Keyboard from 'react-simple-keyboard'

import {
	displayLetters,
	displaySt,
	domainsLayout,
	layoutLetters,
	layoutSt,
} from '../utils/typeKeyBoard'
import {
	emailValidNotRequired,
	numberValid,
	personalNumber,
} from '../utils/validators/validators'

type Inputs = {
	summa: string
	personalNumber: string
	mail: string
}

export const SbpPage = () => {
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [currentInput, setCurrentInput] = useState<null | string>(null)
	const [open, setOpen] = useState(false)
	const [isCorrect, setIsCorrect] = useState<boolean>(false)
	const [typeKeyboard, setTypeKeyboard] = useState<string>('numbers')

	const {
		register,
		control,
		getValues,
		trigger,
		setValue,
		watch,
		formState: { errors },
	} = useForm<Inputs>()

	const onFocus = (name: string) => {
		if (currentInput && currentInput !== name) {
			//@ts-ignore
			trigger(currentInput)
		}
		setCurrentInput(name)
	}
	const onSubmit = (data: any) => {
		trigger().then((result) => {
			if (result) {
				console.log(data)
			}
		})
	}
	const onKeyPress = (button: any) => {
		if (button === '{enter}') {
			//onSubmit(getValues());
		} else {
			if (currentInput) {
				if (button === '{bksp}') {
					//@ts-ignore
					setValue(currentInput, `${getValues(currentInput).slice(0, -1)}`)
				} else {
					//@ts-ignore
					setValue(currentInput, `${getValues(currentInput)}${button}`)
				}
			}
		}
	}

	useEffect(() => {
		if (currentInput === 'mail') {
			setTypeKeyboard('text')
		} else {
			setTypeKeyboard('numbers')
		}
	}, [currentInput])

	useEffect(() => {
		setCurrentInput('personalNumber')
	}, [])

	return (
		<>
			<div className={`transfer payment ${typeKeyboard === 'text' && '_text'}`}>
				<div className="transfer__top">
					<Button
						className="transfer__info"
						type="primary"
						shape="circle"
						size="large"
						style={{ width: '65px', height: '65px' }}
						onClick={() => setIsModalOpen(true)}
						icon={<QuestionCircleOutlined style={{ fontSize: '40px' }} />}
					></Button>

					<h2 className="transfer__title title">Оплата газа через сбп</h2>
					<form className="transfer__form">
						<ul className="transfer__list">
							<li className="transfer__item">
								<label className="transfer__label" htmlFor="personalNumber">
									Номер лицевого счета
								</label>
								<div className="transfer__input-wrap">
									<div className="transfer__input-inner">
										<input
											className={`transfer__input ${errors.personalNumber ? '_error' : ''}`}
											id="personalNumber"
											type="text"
											placeholder="123456789012"
											onFocus={() => onFocus('personalNumber')}
											{...register('personalNumber', personalNumber)}
										/>
									</div>

									<div className="transfer__input-text">
										Введите 8 или 12 цифр лицевого счета
									</div>
								</div>
								<div className="transfer__input-error">
									{errors.personalNumber && errors.personalNumber.message}
								</div>
							</li>
							<li className="transfer__item">
								<label className="transfer__label" htmlFor="summa">
									Сумма платежа
								</label>
								<div className="transfer__input-wrap">
									<div className="transfer__input-inner">
										<input
											className={`transfer__input ${errors.summa ? '_error' : ''}`}
											id="summa"
											type="text"
											placeholder="123456789012"
											onFocus={() => onFocus('summa')}
											{...register('summa', numberValid)}
										/>
									</div>

									<div className="transfer__input-text">
										Введите сумма плтежа с точностью до рубля
									</div>
								</div>
								<div className="transfer__input-error">
									{errors.summa && errors.summa.message}
								</div>
							</li>
							<li className="transfer__item">
								<label className="transfer__label" htmlFor="mail">
									Электронная почта
								</label>
								<div className="transfer__input-wrap">
									<div className="transfer__input-inner">
										<input
											className={`transfer__input ${errors.mail ? '_error' : ''}`}
											id="mail"
											type="text"
											placeholder="example@example.ru"
											onFocus={() => onFocus('mail')}
											{...register('mail', emailValidNotRequired)}
										/>
									</div>

									<div className="transfer__input-text">
										Почта для отправки чека об операции. Если вы оставите поле
										пустым, ваш чек будет хранится в Обществе
									</div>
								</div>
								<div className="transfer__input-error">
									{errors.mail && errors.mail.message}
								</div>
							</li>
						</ul>
					</form>
					<button
						className="transfer__btn btn"
						onClick={() => onSubmit(getValues())}
						disabled={!isCorrect}
					>
						Продолжить
					</button>
				</div>

				<div className="transfer__bottom ">
					{typeKeyboard === 'numbers' && (
						<Keyboard
							onKeyPress={onKeyPress}
							layout={layoutSt}
							display={displaySt}
						/>
					)}
					{typeKeyboard === 'text' && (
						<div style={{ display: 'flex' }}>
							<Keyboard
								baseClass={'simple-keyboard-numpadStart'}
								onKeyPress={onKeyPress}
								layout={layoutLetters}
								display={displayLetters}
							/>
							<Keyboard
								baseClass={'simple-keyboard-numpadEnd'}
								mergeDisplay={true}
								onKeyPress={onKeyPress}
								layout={domainsLayout}
								display={displayLetters}
							/>
						</div>
					)}
				</div>
			</div>
			<Modal
				title="Оплата через СБП"
				open={isModalOpen}
				className="modal-text"
				width={'800px'}
				onCancel={() => setIsModalOpen(false)}
				footer={[
					<Button type="primary" onClick={() => setIsModalOpen(false)}>
						Закрыть
					</Button>,
				]}
			>
				<ol>
					<li>
						Нажмите на кнопку «Оплатить по QR-коду» в разделе «ОПЛАТА ГАЗА»
						данного терминала
					</li>
					<li>
						Введите <b>Номер лицевого счёта абонента</b> (8 или 12 символов),{' '}
						<b>Адрес электронной почты</b>
						(будет использован для получения вами чека платежа) и{' '}
						<b>Сумму платежа</b>.
					</li>
					<li>
						Нажмите кнопку <b>«Отправить»</b>
					</li>
					<li>
						На экране терминала отобразится QR-код для оплаты и параметры
						платежа, которые вы ввели, проверьте введенные данные, если что-то
						неверно вы можете нажать кнопку <b>«Внести изменения»</b> или{' '}
						<b>«Отменить платеж»</b>
					</li>
				</ol>
				<p>
					<b>
						Внимание!{' '}
						<span style={{ textDecoration: 'underline' }}>QR-код</span>,
						отображенный на экране,{' '}
						<span style={{ textDecoration: 'underline' }}>
							действителен 2 минуты
						</span>
						.
					</b>
					Платеж нужно совершить в течении этого времени.
				</p>
				<ol start={5}>
					<li>
						Если данные для оплаты верны, откройте на смартфоне{' '}
						<span style={{ textDecoration: 'underline' }}>
							приложение своего банка
						</span>{' '}
						(например Сбербанк-онлайн, Телекард, Альфа-Клик и др.).
					</li>
					<li>
						В разделе <b>«Платежи»</b> нажмите кнопку <b>«Оплата по QR-коду»</b>{' '}
						(название может отличаться) или найдите кнопку с соответствующей
						пиктограммой. Откроется видоискатель камеры.
					</li>
					<li>
						Наведите камеру на QR-код. После считывания QR-кода в приложении
						банка появится платежная форма со следующими данными
						<ul>
							<li>
								<span style={{ textDecoration: 'underline' }}>
									наименование получателя
								</span>{' '}
								- ООО «Газпром межрегионгаз Оренбург»,
							</li>
							<li>
								<span style={{ textDecoration: 'underline' }}>
									назначение платежа
								</span>{' '}
								- «Оплата газа ЛС (<i>номер лицевого который вы ввели</i>)»
							</li>
							<li>
								<span style={{ textDecoration: 'underline' }}>
									сумма к оплате
								</span>{' '}
								- указанная вами сумма.
							</li>
						</ul>
						Проверьте, правильно ли указаны все данные.
					</li>
					<li>
						Для подтверждения платежа Нажмите <b>«Оплатить»</b> или{' '}
						<b>«Подтвердить»</b> (банки могут использовать разные названия
						кнопок). При достаточности средств на вашем счете, происходит
						списание, поставщик газа и вы получите уведомление об оплате.
					</li>
				</ol>
				<h3>Чек и зачисление средств</h3>
				<ul>
					<li>
						В результате успешного проведения платежа на Электронную почту,
						указанную вами, будет направлен чек об операции.
					</li>
					<li>Оплата отразится на вашем лицевом счёте в течение 1-3-х дней.</li>
				</ul>
			</Modal>
			<Modal
				open={open}
				centered
				className="modal-text"
				title="Qr-Код для оплаты газа"
				footer={[
					<div className="transfer__btn-wrap">
						<Button
							onClick={() => setOpen(false)}
							className="btn transfer__btn-modal"
						>
							Внести изменения
						</Button>

						<Button
							onClick={() => setOpen(false)}
							className="btn transfer__btn-modal"
						>
							Отменить платеж
						</Button>
					</div>,
				]}
			>
				<div className="payment__modal-sbp-qr-wrap">
					<img
						className="payment__modal-sbp-qr"
						src="https://free-png.ru/wp-content/uploads/2022/06/free-png.ru-82.png"
						alt=""
					/>
				</div>
				<div className="payment__modal-sbp-text">
					Отсканируйте qr-код своим банковским приложением
				</div>
				<div className="payment__modal-sbp-time">
					<span>До окончания соединения </span>
					<span>117</span>
					<span> сек</span>
				</div>

				<div className="transfer__modal-inner">
					<div className="transfer__modal-title">Лицевой счет:</div>
					<div className="transfer__modal-text">234432</div>
				</div>
				<div className="transfer__modal-inner">
					<div className="transfer__modal-title">Сумма:</div>
					<div className="transfer__modal-text">132312</div>
				</div>
			</Modal>
		</>
	)
}
